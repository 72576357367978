<template>
  <div class="st-input" :class="wrapperClasses" :data-t="dataT">
    <div class="top-labels">
      <div v-if="label" class="label">
        <span>{{ label }}</span>
        <slot name="tip" />
      </div>
      <div class="right-label-section">
        <div
          v-if="captionTopFirst"
          class="top-caption"
          data-t="caption-top-first"
        >
          {{ captionTopFirst }}
        </div>
        <div
          v-if="captionTopSecond"
          class="top-caption"
          data-t="caption-top-second"
        >
          {{ captionTopSecond }}
        </div>
        <div class="top-caption caption-link">
          <slot name="caption-top-link"></slot>
        </div>
      </div>
    </div>
    <div class="st-input-wrapper">
      <input
        ref="input"
        v-model="inputValue"
        v-maska
        :data-maska="mask"
        data-maska-eager
        data-t="input"
        class="input"
        :type="type"
        :placeholder="placeholder"
        :class="inputClasses"
        :autofocus="autofocus"
        :autocomplete="autocomplete"
        :inputmode="inputmode"
        :maxlength="maxlength"
        :disabled="disabled || notEditable"
        @focus="emit('focus')"
        @blur="emit('blur')"
      />
      <StIcon
        v-if="iconPrefix"
        data-t="icon-prefix"
        class="st-input-prefix-icon"
        :name="iconPrefix"
        :size="iconSize"
      />
      <div class="st-input-postfix-section">
        <slot name="postfix">
          <slot name="postfix-button" />
          <slot name="icon-posfix">
            <StIcon
              v-if="iconPostfix"
              class="st-input-postfix-icon"
              :name="iconPostfix"
              :size="postfixIconSize"
              data-t="icon-postfix"
            />
          </slot>
        </slot>
      </div>
    </div>
    <StTransitionExpand>
      <div v-if="hint || (error && errorMessage)">
        <span v-if="error && errorMessage" class="error-message" data-t="error">
          {{ errorMessage }}
        </span>
        <span v-else-if="hint" class="hint" data-t="hint">{{ hint }}</span>
      </div>
    </StTransitionExpand>
  </div>
</template>

<script setup lang="ts">
import { vMaska } from 'maska/vue'
import type { StInputProps, StInputSlots } from './types'

const {
  type = 'text',
  label = '',
  placeholder = '',
  hint = '',
  errorMessage = '',
  autocomplete = '',
  maxlength = 999,
  inputmode = 'text',
  dataT = 'st-input',
  size = 'l',
  notEditable = false,
  error,
  disabled,
  autofocus,
  iconPrefix,
  iconPostfix,
  captionTopFirst,
  captionTopSecond,
  mask,
} = defineProps<StInputProps>()

const emit = defineEmits<{
  blur: [void]
  focus: [void]
}>()

const input = useTemplateRef<HTMLInputElement>('input')

onMounted(() => {
  if (autofocus) input.value?.focus()
})

const inputValue = defineModel<string>({ default: '' })

const wrapperClasses = computed(() => ({
  disabled,
  [`size-${size}`]: size,
}))

const inputClasses = computed(() => ({
  error: !!error,
}))

const iconPadding = computed(() => {
  if (size === 's') {
    return iconPrefix ? '32px' : '8px'
  }

  if (size === 'm') {
    return iconPrefix ? '40px' : '10px'
  }

  return iconPrefix ? '48px' : '16px'
})

const iconSize = computed(() => {
  switch (size) {
    case 's':
      return 16
    case 'm':
      return 20
    default:
      return 24
  }
})
const postfixIconSize = computed(() => (size === 'l' ? 20 : 16))

defineExpose({
  $el: input,
})

defineSlots<StInputSlots>()
</script>

<style scoped>
.st-input {
  position: relative;
  width: 100%;

  &.disabled {
    pointer-events: none;
    opacity: 0.48;
  }
}

.st-input-wrapper {
  display: flex;
  align-items: center;

  input {
    &.error {
      box-shadow: 0 0 0 1px var(--system-error) inset;
    }
  }

  &:hover {
    input {
      box-shadow: 0 0 0 1px var(--button-primary-default) inset;
    }
  }
}

.st-input-prefix-icon {
  position: absolute;
  left: var(--spacing-150);
  opacity: 0.48;

  &:hover {
    opacity: 1;
  }
}

.input {
  display: block;

  width: 100%;
  padding: var(--spacing-150) var(--spacing-200);
  padding-left: v-bind(iconPadding);

  font: var(--desktop-text-md-medium);
  color: var(--text-primary);

  background-color: var(--background-secondary);
  border: none;
  border-radius: var(--border-radius-100);
  outline: none;
  caret-color: var(--text-primary);

  &::placeholder {
    font: var(--desktop-text-md-medium);
    color: var(--text-quaternary);
  }

  &:focus {
    box-shadow:
      0 0 0 1px var(--button-primary-default) inset,
      var(--focus-rings-button-primary);

    + .st-input-prefix-icon {
      opacity: 1;
    }
  }

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    transition: all 5000s ease-in-out 5000s;
    transition-property: color, background-color;
  }

  &:focus.error {
    box-shadow:
      0 0 0 1px var(--system-error) inset,
      var(--focus-rings-button-destructive);
  }
}

.hint {
  margin-top: var(--spacing-100);
  font: var(--desktop-text-xs-medium);
  color: var(--text-secondary);
}

.error-message {
  margin-top: var(--spacing-100);
  font: var(--desktop-text-xs-medium);
  color: var(--system-error);
}

.st-input-postfix-section {
  position: absolute;
  right: var(--spacing-150);
  display: flex;
  align-items: center;

  .st-input-postfix-icon {
    color: var(--text-quaternary);

    &:hover {
      color: var(--palette-light-1000);
    }
  }
}

.multivalue-input {
  display: flex;
  gap: var(--spacing-100);
  align-items: baseline;

  width: 100%;
  height: 100%;

  .second-value {
    font: var(--desktop-text-xs-medium);
    color: var(--palette-light-600);
  }

  .third-value {
    flex-grow: 1;
    padding-right: var(--spacing-400);
    text-align: right;
  }
}

.top-labels {
  display: flex;
  justify-content: space-between;
}

.label {
  display: flex;
  align-items: center;
  justify-content: space-between;

  margin-bottom: var(--spacing-075);

  font: var(--desktop-text-sm-medium);
  color: var(--text-secondary);
}

.right-label-section {
  display: flex;
  gap: var(--spacing-050);
  justify-content: flex-end;

  .top-caption {
    font: var(--desktop-text-xs-medium);
    color: var(--text-quaternary);
  }

  .top-caption:nth-child(2) {
    color: var(--palette-light-1000);
  }

  .caption-link {
    color: var(--text-link);
  }
}

/* stylelint-disable */
.st-input.size-s {
  .input {
    padding: var(--spacing-100);
    padding-left: v-bind(iconPadding);
    font: var(--desktop-text-xs-medium);

    &::placeholder {
      font: var(--desktop-text-xs-medium);
    }
  }

  .label {
    font: var(--desktop-text-xs-medium);
  }

  .st-input-prefix-icon {
    left: var(--spacing-100);
  }

  .st-input-postfix-section {
    right: var(--spacing-100);
  }

  .right-label-section {
    .top-caption {
      font: var(--desktop-text-xs-medium);
    }
  }
}
/* stylelint-enable */

.st-input.size-m {
  .input {
    padding: var(--spacing-125);
    padding-left: v-bind(iconPadding);
    font: var(--desktop-text-sm-medium);

    &::placeholder {
      font: var(--desktop-text-sm-medium);
    }
  }

  .st-input-prefix-icon {
    left: var(--spacing-125);
  }

  .st-input-postfix-section {
    right: var(--spacing-125);
  }

  .right-label-section {
    .top-caption {
      font: var(--desktop-text-xs-medium);
    }
  }
}
</style>
